<template>
  <div>
    <b-card :title="$t('report.general.search')">
      <b-form @submit.prevent="searchData">
        <b-row>
          <b-col md="6" lg="6">
            <b-form-group :label="$t('work.startDate')">
              <flat-pickr v-model="search.workStartDate" class="form-control" :config="$store.state.main.datePickerConfig"/>
            </b-form-group>
          </b-col>

          <b-col md="6" lg="6">
            <b-form-group :label="$t('work.endDate')">
              <flat-pickr v-model="search.workEndDate" class="form-control" :config="$store.state.main.datePickerConfig"/>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-center">
            <b-button type="submit" variant="info" class="my-1 mr-1">
              <feather-icon icon="SearchIcon"/>
              {{ $t('general.btnSearch') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>

    </b-card>

    <b-overlay :show="isShowLoading">
      <b-card :title="$t('report.general.workPermitName')">

        <div class="text-right mb-2">
          <b-button variant="primary" @click="exportExcel">Export Excel</b-button>
        </div>

        <!-- table -->
        <vue-good-table
            mode="remote"
            :columns="tableData.columns"
            :rows="tableData.rows"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :totalRows="tableData.totalRecords"
            :isLoading.sync="tableData.isLoading"
            :pagination-options="{ enabled: true }"
        >
          <template
              slot="table-row"
              slot-scope="props"
          >
            <div v-if="props.column.field === 'action'" class="text-nowrap text-center">
              <b-button
                  type="button"
                  variant="warning"
                  :to="{name: 'pageMasterDepartmentEdit', params: {id: props.row.departmentId}}"
                  size="sm"
                  class="mr-1"
              >
                <feather-icon icon="Edit2Icon"/>
              </b-button>
              <b-button
                  type="button"
                  variant="danger"
                  size="sm"
                  @click="deleteData(props.row.departmentId)"
              >
                <feather-icon icon="Trash2Icon"/>
              </b-button>
            </div>

            <div v-else>{{ props.formattedRow[props.column.field] }}</div>
          </template>

          <!-- pagination -->
          <template
              slot="pagination-bottom"
              slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('general.tableShowing') }} 1 {{ $t('general.tableTo') }}
            </span>
                <b-form-select
                    v-model="tableData.pageLength"
                    :options="tableData.pageLengthOption"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> {{ $t('general.tableOf') }} {{ props.total }} {{ $t('general.tableEntries') }} </span>
              </div>
              <div>
                <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="tableData.pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-overlay>
  </div>

</template>

<script>
import {BAvatar, BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BFormSelect, BOverlay, BPagination, BRow} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import tableMixins from "@/mixins/tableMixins"
import {ReportService} from "@/services"
import {utils, writeFile} from 'xlsx'
import textTh from '@/libs/i18n/locales/th.json'
import textEn from '@/libs/i18n/locales/en.json'

const reportService = new ReportService()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BPagination,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BOverlay,
    VueGoodTable
  },
  mixins: [tableMixins],
  created() {
    this.tableData.columns = [
      {
        label: this.$t('report.workPermit.number'),
        exportKey: 'number',
        field: 'workCode',
        width: '150px',
        sortable: false
      },
      {
        label: this.$t('report.workPermit.status'),
        exportKey: 'status',
        field: 'workStepResult',
        width: '200px',
        sortable: false
      },
      {
        label: this.$t('report.workPermit.create'),
        exportKey: 'create',
        field: 'workCreateDate',
        width: '150px',
        sortable: false
      },
      {
        label: this.$t('report.workPermit.duration'),
        exportKey: 'duration',
        field: 'workDateCreate',
        width: '200px',
        sortable: false
      },
      {
        label: this.$t('report.workPermit.period'),
        exportKey: 'period',
        field: 'workTimeCreate',
        width: '200px',
        sortable: false
      },
      {
        label: this.$t('report.workPermit.requester'),
        exportKey: 'requester',
        field: 'workContractorAdminName',
        width: '200px',
        sortable: false
      },
      {
        label: this.$t('report.workPermit.tel'),
        exportKey: 'tel',
        field: 'workContractorAdminTel',
        width: '200px',
        sortable: false
      },
      {
        label: this.$t('report.workPermit.company'),
        exportKey: 'company',
        field: 'companyName',
        width: '200px',
        sortable: false
      },
      {
        label: this.$t('report.workPermit.section'),
        exportKey: 'section',
        field: 'departmentName',
        width: '200px',
        sortable: false
      },
      {
        label: this.$t('report.workPermit.jobDetail'),
        exportKey: 'jobDetail',
        field: 'workScope',
        width: '300px',
        sortable: false
      },
      {
        label: this.$t('report.workPermit.place'),
        exportKey: 'place',
        field: 'locationName',
        width: '200px',
        sortable: false
      },
      {
        label: this.$t('report.workPermit.type'),
        exportKey: 'type',
        field: 'workTypeName',
        width: '200px',
        sortable: false
      },
      {
        label: this.$t('report.workPermit.numOfWorkers'),
        exportKey: 'numOfWorkers',
        field: 'workerTotal',
        width: '150px',
        sortable: false
      },
      {
        label: this.$t('report.workPermit.workOwnerName'),
        exportKey: 'workOwnerName',
        field: 'workWorkOwnerApproveName',
        width: '200px',
        sortable: false
      },
      {
        label: this.$t('report.workPermit.workOwnerTel'),
        exportKey: 'workOwnerTel',
        field: 'workWorkOwnerApproveTel',
        width: '200px',
        sortable: false
      },
      {
        label: this.$t('report.workPermit.areaOwnerName'),
        exportKey: 'areaOwnerName',
        field: 'workAreaOwnerApproveName',
        width: '200px',
        sortable: false
      },
      {
        label: this.$t('report.workPermit.areaOwnerTel'),
        exportKey: 'areaOwnerTel',
        field: 'workAreaOwnerApproveTel',
        width: '200px',
        sortable: false
      },
      {
        label: this.$t('report.workPermit.managerName'),
        exportKey: 'managerName',
        field: 'workManagerApproveName',
        width: '200px',
        sortable: false
      },
      {
        label: this.$t('report.workPermit.managerTel'),
        exportKey: 'managerTel',
        field: 'workManagerApproveTel',
        width: '200px',
        sortable: false
      },
      {
        label: this.$t('report.workPermit.safetyName'),
        exportKey: 'safetyName',
        field: 'workSafetyApproveName',
        width: '200px',
        sortable: false
      },
      {
        label: this.$t('report.workPermit.safetyTel'),
        exportKey: 'safetyTel',
        field: 'workSafetyApproveTel',
        width: '200px',
        sortable: false
      },
      {
        label: this.$t('report.workPermit.extendPeriod'),
        exportKey: 'extendPeriod',
        field: 'workDateExtend',
        width: '200px',
        sortable: false
      },
      {
        label: this.$t('report.workPermit.extendTime'),
        exportKey: 'extendTime',
        field: 'workTimeExtend',
        width: '200px',
        sortable: false
      },
      {
        label: this.$t('report.workPermit.closeDate'),
        exportKey: 'closeDate',
        field: 'workClose',
        width: '150px',
        sortable: false
      },
    ]
    this.listData()
  },
  data: () => ({
    isShowLoading: true,
    pk: '',
    results: [],
    search: {
      workStartDate: '',
      workEndDate: '',
    },
  }),
  computed: {},
  watch: {
    tableChange() {
      this.listData()
    }
  },
  methods: {
    listData() {
      this.getTableQueryString()
      reportService.listWorkPermit(this.tableQueryString).then(queryResult => {
        let {isSuccess, data} = queryResult
        if (isSuccess) {
          this.results = data.results
          this.tableData.rows = data.results
          this.tableData.totalRecords = data.totalRow
        } else {
          this.$store.commit('main/setToastError', data)
        }
        this.isShowLoading = false
      })
    },
    searchData() {
      this.onColumnFilter(this.search)
    },
    exportExcel() {
      let results = []
      let columnWidth = []
      let th = textTh.report.workPermit
      let en = textEn.report.workPermit

      this.getTableQueryString()
      reportService.listWorkPermit({...this.tableQueryString, page: 0}).then(queryResult => {
        let {data} = queryResult

        let rawResults = data.results
        rawResults.map(result => {
          let columnObject = {}
          for (const [thaiKey, thaiItem] of Object.entries(th)) {
            let getKeyResult = this.tableData.columns.find(data => data.exportKey === thaiKey)
            let dataResult = getKeyResult ? result[getKeyResult.field] : ''
            let columnHeader = `${en[thaiKey]} - ${thaiItem}`
            columnObject[columnHeader] = dataResult
            columnWidth.push({wch: columnHeader.length})
          }

          results.push(columnObject)
        })

        const worksheet = utils.json_to_sheet(results)
        const workbook = utils.book_new()
        worksheet["!cols"] = columnWidth
        utils.book_append_sheet(workbook, worksheet, 'Work Permit')
        writeFile(workbook, 'work-permit.xlsx')
      })


    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

